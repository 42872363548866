/* Landing heading animation  */

.expand-enter {
  opacity: 0;
  width: 10%;
}

.expand-enter.expand-enter-active {
  width: 80%;
  opacity: 1;
  transition: all 400ms cubic-bezier(0.34, 0.125, 0.225, 1.295) 1000ms;
}

.expand-enter-done {
  width: 80%;
  opacity: 1;
}

.expand-exit {
  width: 80%;
}

.expand-exit-active.expand-exit {
  width: 80%;
}

@media (max-width: 850px) {
  .expand-enter.expand-enter-active {
    width: 85%;
  }

  .expand-enter-done {
    width: 85%;
  }
}

@media (max-width: 450px) {
  .expand-enter.expand-enter-active {
    width: 90%;
  }

  .expand-enter-done {
    width: 90%;
  }
}

/* Carousel Appear animation  */

.move-down-enter {
  transform: translateY(-50%);
  opacity: 0;
}

.move-down-enter.move-down-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 400ms cubic-bezier(0.34, 0.125, 0.225, 1.295) 400ms;
}

.move-down-enter-done {
  transform: none;
}

/* Page heading animation  */

.widen-enter {
  width: 200px;
}

.widen-enter.widen-enter-active {
  width: 600px;
  transition: width 600ms cubic-bezier(0.3, 0.015, 0.42, 1.305) 400ms;
}

.widen-enter-done {
  width: 600px;
}

@media (max-width: 850px) {
  .widen-enter {
    width: 200px;
  }

  .widen-enter.widen-enter-active {
    width: 70%;
  }

  .widen-enter-done {
    min-width: 400px;
    width: 70%;
  }
}

@media (max-width: 450px) {
  .widen-enter {
    min-width: 0;
    width: 30%;
  }

  .widen-enter.widen-enter-active {
    min-width: 0;
    width: 85%;
  }

  .widen-enter-done {
    min-width: 0;
    width: 85%;
  }
}

/* standard fade-in and out transition  */

.fadein-enter {
  opacity: 0;
}

.fadein-enter.fadein-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;
}

.fadein-enter-active > *,
.fadein-enter-done > * {
  transform: var(--transform) !important;
}

.fadein-enter-done {
  opacity: 1 !important;
  transition-delay: 0ms !important;
}

.fadein-exit {
  opacity: 1;
}

.fadein-exit.fadein-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}

.fadein-exit-done {
  opacity: 0;
}

/* Cross-fade transition */

.crossfade-enter {
  opacity: 0;
  position: absolute !important;
}

.crossfade-enter.crossfade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;
}

.crossfade-enter-done {
  opacity: 1 !important;
  transition-delay: 0ms !important;
}

.crossfade-exit {
  opacity: 1;
  position: absolute !important;
}

.crossfade-exit.crossfade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}

.crossfade-exit-done {
  opacity: 0;
}

/* Alert message transition  */

.fade-enter {
  opacity: 0;
  transform: scaleY(0);
}

.fade-enter .alert__shadow {
  opacity: 0 !important;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: bottom;
  transition: all 400ms cubic-bezier(0.635, -0.09, 0.65, 1.62);
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit .alert__shadow {
  opacity: 0 !important;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: all 400ms ease;
}

.fade-exit-done {
  opacity: 0;
  transform: scaleY(0);
}

/* page swipe transition  */

.slide-enter {
  position: absolute;
  opacity: 0;
}

.slide-enter ~ footer {
  opacity: 0;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  height: 100%;
  position: absolute;
  z-index: 3;
  transition: opacity 300ms ease-out 400ms;
}

.page-content.slide-enter.slide-enter-active,
.main-landing__wrapper.slide-enter.slide-enter-active,
.page-content.slide-exit,
.main-landing__wrapper.slide-exit {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 450px) {
  .page-content.slide-enter.slide-enter-active,
  .main-landing__wrapper.slide-enter.slide-enter-active,
  .page-content.slide-exit,
  .main-landing__wrapper.slide-exit {
    width: 100vw !important;
  }
}

.page-content.slide-enter.slide-enter-active,
.page-content.slide-exit {
  width: 97vw;
}

.main-landing__wrapper.slide-enter.slide-enter-active,
.main-landing__wrapper.slide-exit {
  width: 100%;
}

.slide-enter.slide-enter-active ~ footer {
  opacity: 0;
}

.slide-enter-done {
  position: relative;
  opacity: 1;
}

.slide-enter-done ~ footer {
  opacity: 1;
  transition: opacity 400ms ease;
}

.slide-exit {
  opacity: 1;
  z-index: 3;
  position: absolute;
}

.slide-exit ~ footer {
  opacity: 0;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  position: absolute;
  transition: opacity 100ms ease-in;
}

.slide-exit.slide-exit-active ~ footer {
  opacity: 0;
}

.slide-exit-done {
  opacity: 0;
}

.slide-exit-done ~ footer {
  opacity: 0;
}

/* Form switch transition  */

.switch-enter {
  opacity: 0;
  position: absolute;
}

.switch-enter.switch-enter-active {
  opacity: 0;
}

.switch-enter-done {
  /* position: inherit; */
  opacity: 1 !important;
  transition: opacity 200ms ease;
}

.switch-exit {
  opacity: 0;
  transition: opacity 100ms ease;
}

.switch-exit.fadein-exit-active {
  opacity: 0;
  position: absolute;
}

.switch-exit-done {
  opacity: 0;
}

/* Brand colors flying in  */

.fly-enter {
  transform: translate(-1000px, -400px);
}

.fly-enter.fly-enter-active {
  transform: translate(0);
  transition: all 2000ms linear 200ms;
}

.fly-enter-done {
  transform: translate(0);
}

/* Form elements appearing */

.drop-in-enter {
  max-height: 0;
  opacity: 0;
}

.drop-in-enter-active {
  max-height: 50px;
  opacity: 1;
  transition: max-height 200ms ease, opacity 200ms ease;
}

.drop-in-enter-done {
  max-height: 50px;
  opacity: 1;
}

.drop-in-exit {
  max-height: 50px;
  opacity: 1;
}

.drop-in-exit-active {
  opacity: 0 !important;
  max-height: 0 !important;
  transition: max-height 200ms ease, opacity 200ms ease;
}

.drop-in-exit-done {
  max-height: 0;
  opacity: 0;
}

@media (max-width: 450px) {
  .upload-prints--dimensions.drop-in-enter-active {
    max-height: 80px;
  }

  .upload-prints--dimensions.drop-in-enter-done {
    max-height: 80px;
  }

  .upload-prints--dimensions.drop-in-exit {
    max-height: 80px;
  }
}
