/* Navbar header Styles  */

header {
  background-color: var(--dark);
  color: var(--white-two);
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
}

header h1 {
  font-family: var(--yellowtail);
  font-weight: lighter;
  margin: 0;
}

header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header li {
  padding: 1rem;
}

header li:last-of-type {
  display: flex;
}

header li:last-of-type::before {
  content: "";
  border-left: 3px double var(--white-two);
  padding: 1rem;
  max-height: 100%;
  display: inline-block;
}

header li:last-of-type a:active {
  animation: press 100ms ease;
}

header a {
  box-shadow: none;
  transition: all 150ms linear;
}

header a:hover {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  border-bottom: 4px inset var(--white-two);
}

header a:active {
  animation: press-text 100ms ease;
}

header a.header--main-link {
  transition: text-shadow 150ms ease;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

header a.header--main-link:hover {
  border-bottom: none;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.7);
}

header a.header--main-link:active {
  animation: press-text 150ms linear forwards;
}

.header-links a:not(.login-edit, .cart-link) {
  padding: 0.5rem 1rem;
}

.MuiBadge-badge {
  background-color: var(--medium);
  color: var(--white);
  padding: 0 3px;
}

header .cart-link:visited,
header .cart-link {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border-radius: 8px;
  transition: box-shadow 200ms ease;
  color: var(--white-two);
}

header .cart-link:hover {
  border-bottom: none;
  text-shadow: none;
  box-shadow: 0 0 0 1px inset var(--white-two), 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

header .cart-link:active > span {
  border: none;
  animation: press-cart 150ms ease forwards;
}

.login-edit {
  padding: 4px;
  border-radius: 4px;
  display: flex;
}

.login-edit i {
  color: var(--white-two);
  text-shadow: none;
}

.login-edit:hover {
  border-bottom: none;
  box-shadow: 0 0 0 1px inset var(--light-two), 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

.login-edit:active path {
  transform: scale(1);
  animation: press-cart 100ms ease forwards;
}

.menu {
  position: absolute;
  z-index: 200;
  top: 75px;
  right: 0;
  background-color: var(--dark);
  transform: translateX(101%);
  transition: transform 200ms ease;
  border: 1px solid black;
  border-top: none;
  border-right: none;
  border-radius: 0 0 0 8px;
}

.menu__burger-icon {
  width: 36px;
  height: 32px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: initial;
  gap: 5px;
  margin: 0;
  padding: 4px 0;
  cursor: pointer;
  line-height: 1;
  background-color: transparent;
  border: none;
  box-shadow: none;
  transition: none;
}

.menu__burger-icon::after {
  display: none;
}

.menu__burger-icon:hover,
.menu__burger-icon:active,
.menu__burger-icon:focus {
  width: 36px;
  box-shadow: none;
  background-color: inherit;
  transition: none;
  animation: none;
}

.menu__burger-icon:focus {
  outline: auto;
  outline-offset: 2px;
}

.menu__burger-icon span {
  position: relative;
  display: block;
  background-color: var(--white-two);
  height: 4px;
  border-radius: 3px;
  transition: transform 200ms ease;
  transform-origin: 5px 5px;
}

.menu__burger-icon span:last-child {
  transform-origin: 5px -1px;
}

@media (min-width: 850px) {
  header ul {
    flex-direction: row;
  }

  header li {
    padding: 0;
  }

  .menu__burger-badge,
  .menu__burger-icon {
    display: none !important;
  }

  .menu {
    transform: none !important;
    position: static;
    border: none;
  }

  header li:last-of-type {
    border: none;
    margin-right: 0;
  }
}

@media (max-width: 550px), (max-height: 550px) {
  header {
    padding: 4px 1rem;
  }

  .menu {
    top: 52px;
  }
}

@media (max-width: 850px) {
  header li:last-of-type {
    flex-direction: column;
    align-items: center;
  }

  header li:last-of-type::before {
    border-left: none;
    border-top: 3px double var(--white-two);
    width: 100%;
  }
}

@media (max-height: 550px) {
  .header-links {
    flex-direction: row;
  }

  header li {
    margin: 0 0.25rem !important;
    padding: 0.5rem;
  }

  header li:last-of-type::before {
    border-left: 3px double var(--white-two);
    border-top: none;
  }
}

@media (max-width: 850px) and (max-height: 550px) {
  header li:last-of-type::before {
    border-left: 3px double var(--white-two);
    border-top: none;
  }

  header li:last-of-type {
    flex-direction: row;
  }
}
