/* Shared Styles  */

@font-face {
  font-family: yellow-tail;
  src: url("./fonts/Yellowtail-Regular.ttf");
  font-display: swap;
}
/* @import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;700;900&display=swap"); */
@font-face {
  font-family: "noto-sans";
  src: url("./fonts/NotoSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "noto-bold";
  src: url("./fonts/NotoSans-Bold.ttf");
  font-display: swap;
}

@import url("./styles/transitions.css");

:root {
  --white: #f9f9f9;
  --light: #ded5c4;
  --medium: #ef7e56;
  --dark: #305973;

  --white-two: #f1f6f9;
  --light-two: #9ba4b4;
  --medium-two: #394867;
  --dark-two: #14274e;

  --yellowtail: "yellow-tail", "cursive";
  --noto: "noto-sans", "sans-serif";
  --noto-bold: "noto-bold", "sans-serif";
}
/* :root {
  --white: #f2f2f0;
  --light: #ff5e3a;
  --medium: #2c365d;
  --dark: #272e4f;
} */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--light-two);
  box-shadow: 0 0 4px 0 inset rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  background: var(--light);
  box-shadow: 0 0 8px 0 inset var(--white);
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: var(--light-two) var(--light);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--white);
  color: var(--dark);
  background-image: url(./images/brushed-alum.webp);
  font-family: var(--noto);
  /* font-family: "Noto Sans SC", sans-serif; */
  transition: opacity 500ms ease;
}

#root {
  height: 100%;
}

#root > div:first-of-type {
  display: grid;
  grid-template-rows: auto fit-content(3rem);
  grid-template-areas: "body" "footer";
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: var(--white-two);
}

i {
  color: var(--dark);
}

.fa-edit {
  transform: translate(1px, -1px);
}

p {
  line-height: 1.4;
  font-size: 1rem;
}

a:visited {
  color: var(--white-two);
}

.input__wrapper {
  position: relative;
}

.input__wrapper:hover > label,
.input__wrapper:focus-within > label,
label.input__wrapper:focus-within,
label.input__wrapper:hover {
  opacity: 1;
}

label {
  font-family: inherit;
  color: var(--dark);
  font-size: 0.875rem;
  opacity: 0.6;
  transition: opacity 200ms ease;
}

label:has(:checked) {
  opacity: 0.9;
}

input {
  font-size: 1.1rem;
  line-height: 2;
  color: inherit;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  font-family: inherit;
  border: none;
  border-bottom: 1px solid var(--dark);
  background-color: inherit;
  transition: box-shadow 200ms ease;
  border-radius: 0px;
  text-indent: 0;
  -webkit-appearance: none;
}

.input__helper {
  width: fit-content;
  left: 100%;
  background-color: var(--white);
  background-image: url(./images/brushed-alum.webp);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: color 5000s ease-in-out 0s, background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: inherit !important;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 -1px 0 0 inset var(--dark), 0 1px 0 0 var(--dark);
}

input[type="checkbox"]:focus {
  box-shadow: none;
}

.file-input__label {
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-bottom: 2rem;
  display: block;
  cursor: pointer;
  border-bottom: 1px solid var(--dark);
  color: var(--dark);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: box-shadow 400ms ease;
}

.file-input__label:hover,
.file-input__label:focus-within {
  box-shadow: 0 3px var(--dark);
}

.file-input__label:hover * {
  opacity: 1;
}

.small-label {
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0.25rem;
}

.file-input__label > svg {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0.7;
  transition: opacity 400ms ease;
}

.file-input__label:active {
  animation: press-label 200ms ease;
}

.file-input__label > .file-input__name {
  max-width: 80vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block;
  font-size: 0.8rem;
}

.file-input__label > span:first-of-type {
  opacity: 0.6;
  transition: opacity 400ms ease;
}

.file-input__label:hover > span:first-of-type,
.file-input__label:focus-within > span:first-of-type {
  opacity: 1;
}

input[type="file"] {
  position: absolute;
  width: 0.01px;
  visibility: hidden;
}

input[type="number"] {
  display: inline-block;
  width: 60px;
  height: 1.7rem;
  margin: 0;
  text-indent: 6px;
  font-size: 1rem;
  line-height: 110%;
}

input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  background-color: var(--white) !important;
  border: none;
  color: var(--medium);
}

textarea {
  font-family: inherit;
  color: inherit;
  width: 100%;
  font-size: 1.2rem;
  margin: 0.5rem 0 1rem;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid var(--dark);
  border-top: 1px dotted var(--dark);
  border-radius: 0;
  background-color: inherit;
  resize: none;
  transition: all 200ms ease;
  background-clip: padding-box;
  outline: none;
}

button {
  width: 160px;
  max-width: 100%;
  margin: 1rem auto 0;
  padding: 0.25rem 0;
  cursor: pointer;
  color: inherit;
  background-color: var(--white);
  font-size: 1.2rem;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 40%);
  transition: width 200ms ease;
  position: relative;
  border: 2px outset var(--dark);
  border-radius: 0.25rem;
  z-index: 1;
}

button:hover,
button:focus {
  box-shadow: 0px 5px 8px 2px rgb(0 0 0 / 30%);
  width: 200px;
  background-color: var(--medium);
  transition: width 200ms ease, background-color 150ms linear 50ms;
}

button:focus {
  outline: none;
}

button:active {
  animation: press 150ms ease forwards;
}

button:disabled {
  cursor: default;
  pointer-events: none;
}

button::after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  background-color: var(--medium);
  padding: 0.25rem 0;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition: transform 200ms ease-in-out;
  z-index: -1;
}

button:hover:after,
button:focus:after {
  transform: scaleY(1);
  transform-origin: 50% 0%;
}

h2 {
  margin-top: 0;
  text-align: center;
}

.alert__wrapper {
  position: fixed;
  bottom: 1rem;
  margin-right: 4rem;
  z-index: 8;
}

.alert {
  display: flex;
  /* box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4); */
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem 0;
  border: 3px solid var(--dark);
  text-align: center;
  transform-style: preserve-3d;
  perspective-origin: 40% 200%;
  perspective: 1000px;
  z-index: 1;
}

.alert::after {
  content: "";
  position: absolute;
  top: 0;
  transform: rotateX(90deg) translateZ(-3px);
  transform-origin: bottom;
  background-color: var(--dark);
  width: 100%;
  height: 100%;
}

.alert__shadow {
  transform: translateZ(-250px);
  width: 110%;
  height: 100%;
  filter: blur(5px);
  z-index: -4;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 100ms ease;
  position: absolute;
}

.alert h2 {
  margin: 0;
}

.page-content {
  position: relative;
  width: 97vw;
  max-width: 70rem;
  margin: 88px auto 1rem auto;
}

.page-header {
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  margin-bottom: 3rem;
  background-color: var(--medium);
  padding: 2rem;
  transition: width 400ms cubic-bezier(0.71, -0.415, 0.415, 1.65);
  overflow: hidden;
  text-transform: uppercase;
  border: 3px solid var(--dark);
  transform-style: preserve-3d;
  font-family: var(--noto-bold);
  font-size: 33px;
  line-height: 45px;
}

.page-header__shadow {
  position: absolute;
  left: 0px;
  top: 7%;
  width: 110%;
  height: 65%;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateZ(-365px);
  filter: blur(11px);
}

.page-header__wrapper {
  position: relative;
  margin-left: 2rem;
  display: inline-block;
  margin-bottom: 2rem;
  transform-style: preserve-3d;
  perspective: 1000px;
  perspective-origin: -18% 150%;
}

.page-header__arrow {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  transform: rotateX(-90deg) translate3d(0, 100%, -63px);
  transform-origin: bottom;
  background: var(--dark);
  filter: brightness(0.85);
}

.page-header__left {
  height: 116px;
  width: 200px;
  position: absolute;
  transform: rotateY(90deg) translateY(22px);
  transform-origin: left;
  background: var(--dark);
}

.brand-backdrop {
  position: absolute;
  top: -1rem;
  left: -2rem;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  max-width: 100%;
  background-color: var(--medium);
  transform: skew(7deg) rotate(-6deg);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.4);
}

.brand-backdrop::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--dark);
  transform: rotate(10deg);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.4);
}

.progress {
  text-align: center;
  margin: 5rem auto;
}

.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  overscroll-behavior: contain;
}

.modal-content {
  opacity: 1;
  border-radius: 8px;
  background: url(./images/brushed-alum.webp) var(--white);
  position: absolute;
  top: 90px;
  padding: 1rem;
  max-width: 95%;
  width: 450px;
  height: fit-content;
  transform: translate(0, -50%);
  transition: transform 200ms ease-in-out;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
  isolation: isolate;
}

.close-modal {
  box-sizing: content-box;
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0 1rem;
  margin: 0;
  border-radius: 4rem;
  height: 32px;
  transition: background-color 200ms ease, box-shadow 200ms ease;
  cursor: pointer;
  line-height: 1;
  width: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.close-modal:hover,
.close-modal:focus {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: auto;
  background-color: transparent;
}

.close-modal::after {
  display: none;
}

.close-modal:active {
  animation: press-cart 150ms linear;
}

.close-modal > svg {
  font-size: 2rem;
  pointer-events: none;
}

.lazy-load-image-background {
  color: inherit !important;
  background-color: transparent;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: inherit;
  color: inherit !important;
  height: 100%;
  width: 100%;
  contain: strict;
}

.loading svg {
  opacity: 0.7;
  height: fit-content;
  width: 50%;
  max-width: 80px;
  min-width: 40px;
}

.loading-dots {
  margin-top: 1rem;
  display: flex;
  gap: 0.25rem;
}

.loading-dots .dot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--dark);
  opacity: 0.7;
  animation: dot-jumps 800ms cubic-bezier(0.25, 0.25, 0.395, 1.26) infinite
    forwards;
}

.loading .broken-link-face {
  width: 40px;
}

.loading .broken-link-text {
  transform: none;
  font-size: 0.875rem;
  margin: 0.25rem;
}

@keyframes dot-jumps {
  10% {
    transform: none;
  }
  40% {
    transform: translateY(var(--jump));
  }

  100% {
    transform: none;
  }
}

@media (max-width: 850px) {
  .page-header__wrapper {
    margin-left: 1rem;
  }
  .page-header__wrapper {
    perspective-origin: -16% 150%;
  }

  .page-header__shadow {
    top: 15%;
    filter: blur(6px);
  }

  .page-header {
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.6rem;
    line-height: 35px;
  }

  .page-header__arrow {
    transform: rotateX(-90deg) translate3d(0, 100%, -111px);
  }

  .page-header__left {
    transform: rotateY(90deg) translateY(17px);
    height: 72px;
  }
}

@media (max-width: 550px) {
  .modal-content {
    top: 60px;
  }
}

@media (max-width: 450px) {
  .page-content {
    padding: 0.5rem;
    width: 100vw;
    margin-bottom: 1rem;
    margin-top: 58px;
  }

  .page-header {
    font-size: 1.4rem;
    line-height: 31px;
  }

  .page-header__arrow {
    transform: rotateX(-90deg) translate3d(0, 100%, -116px);
  }

  .page-header__left {
    transform: rotateY(90deg) translateY(16px);
    height: 69px;
  }

  .alert__wrapper {
    left: 1rem;
    bottom: 0;
  }

  .alert h2 {
    font-size: 1rem;
  }

  .close-modal {
    right: 0;
    top: 0;
    border-radius: 0 8px;
    padding: 0.25rem;
  }

  textarea,
  input {
    font-size: 1rem;
  }
}

@media (hover: none) {
  button {
    width: 160px;
    margin: 1rem auto 0;
    padding: 0.25rem 0;
    cursor: pointer;
    color: inherit;
    background-color: var(--white);
    font-size: 1.2rem;
    font-family: inherit;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    transition: all 200ms ease;
    position: relative;
    border: 2px outset var(--dark);
  }

  button::after {
    content: "";
    position: absolute;
    background-color: var(--medium);
    padding: 0.25rem 0;
    width: 100%;
    left: 0;
    top: 0;
    transform: scaleY(0);
    transition: transform 200ms ease-in-out;
  }
}
